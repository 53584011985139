import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';


export default function FooterBlock() {


    return (
        <Container fluid className='p-3 text-secondary' id="footer">
            <Container fluid='md'>
                <Row>
                    <Col xs={12} md={6} lg={6}>
                        <div className='footer-widget py-3'>
                            <h4>Health 51</h4>
                            <p>
                                Viale Venezia, 51,<br />
                                San Vendemiano (TV), 31020<br />
                                Italia
                            </p>
                            <h4>Contatti</h4>
                            <p>
                                <i>Info</i>: <b><a className="link-secondary" href="mailto:health51@gmail.com">health51@gmail.com</a></b><br />
                                <i>Tel</i>: <b><a className="link-secondary" href="tel:3755035466">3755035466</a></b><br />
                                Lasciaci un messaggio, ti risponderemo il prima possibile.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={2} lg={2}></Col>
                    <Col xs={12} md={4} lg={4}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2777.2996924287786!2d12.317685576338166!3d45.88531890524251!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477915f84f652023%3A0x9f092182d0e32976!2sCrossFit%20Gate51!5e0!3m2!1sit!2sit!4v1731417229473!5m2!1sit!2sit" width="100%" height="300" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </Col>

                </Row>
            </Container>
            <Container fluid='md' className='mt-5 text-center'>
                <p className='text-secondary'>© 2024 Health51. | P.iva 05511640269</p>
            </Container>
        </Container>
    );
};